/* MODULES / LABELS - modules/labels/_labels.scss */
@if $import-labels == true {
  #polarcore {
    %labels {
      .trollweb-labels {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        .trollweb-label {
           color: #fff;

          .discount, .new {
            display: block;
            padding: 0.25rem 1rem;
            background-color: $mdl-red-400;
            text-align: center;
            font-size: 1rem;
          }
        }
      }
    }
    .product-img-box {
      .trollweb-labels {
        .trollweb-label {
          .discount, .new {
            font-size: 1.275rem;
            padding: 0.375rem 1.425rem;
          }
        }
      }
    }
    .product-img-box, .image-wrapper {
      position: relative;

      @extend %labels;
    }
  }
}
