/* PRODUCT-GRID - _product-grid.scss */

.products-grid {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  padding: 0;
  position: relative;
  clear: both;
  .actions {
    display: none;
    text-align: center;
    .add-to-links {
      @include add-to-links();
      display: none;
      padding-top: 2rem;
      li {
        float: left;
        &:last-child {
          padding-right: 0;
        }
        .inner {
          display: block;
          span {
            display: none;
          }
        }
      }
    }
  }
  button.btn-cart {
    display: none;
  }
  .combo-box {
    width: 100%;
    .review-wrapper {
      width: 100%;
      text-align: center;
      .amount {
        position: relative;
        bottom: 0.1rem;
      }
    }
  }
  .configurable-swatch-list {
    list-style: none;
    padding: 0;
    text-align: center;
    li {
      display: inline-block;
      .swatch-label {
        img {
          border: 1px $configurable-swatch-list-border-color solid;
        }
      }
    }
  }
  .item {
    list-style: none;
    padding: 3px;
    background: transparent;
    will-change: box-shadow;
    transition: box-shadow 0.15s ease;
    @media #{$medium-up}{
      &:hover {
        box-shadow: 0px 0px 0px 3px $product-list-hover-color;
      }
    }
    .inner-item {
      .image-wrapper {
        position: relative;
        text-align: center;
        img, a {
          //display: inline-block;
        }
      }
    }
    .product-info {
      display: flex;
      flex-direction: column;
      padding: 1em;
      margin-bottom: 1em;
      //border-bottom: #F0F0F0 2px solid;
      .review-wrapper {
        //height: 2rem;
        font-size: 0.8em;
        .rating-box {
          display:inline-block;
          i {
            position: relative;
            top: 0.1em;
            color: $review-color;
            font-size: 1.5em;
          }
        }
      }
      .product-name {
        text-align: center;
        text-decoration: none;
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin: 0;
        //text-transform: uppercase;
        padding-bottom: 0.5rem;
        a {
          color: $product-name-link-color;
          font-weight: $product-name-font-weight;
          text-decoration: none;
          &:hover {
            color: lighten($product-name-link-color, 25%);
          }
        }
      }
    }
    @include grid-price-box();
    .product-info {
      text-align: left;
      .actions {
        .button.view-details {
          display: none;
        }
        .link-compare span, .link-wishlist span  {
          // display: none;
        }
      }
    }
  }
}
