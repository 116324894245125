/* MODULES / FAVORITE PRODUCTS - modules/favoriteproducts/_favoriteproducts.scss */
@if $import-favoriteproducts == true {
    #polarcore {
        .favoriteproducts-add-button {
            cursor: pointer;
            display: block;
            text-align: center;
            background-color: #c1c1c1;
            border-radius: 4px;
            line-height: 2.4;
            color: #fff;
            margin-bottom: 1rem;
            text-transform: uppercase;
            font-size: 0.825rem;
            transition: background-color 200ms ease-in-out;

            @extend .mdl-shadow--2dp;

            &:hover {
                background-color: #b2b2b2;
            }
        }
    }
}
