/* HEADER - _header.scss */
$header-height: ('small-up': 52px, 'medium-up': 112px);

body {
  .header {
    width: 100%;
    position: relative;
    z-index: 105;
  }
  .header, 
  .mdl-layout__drawer-button {
    will-change: transform, box-shadow;
    backface-visibility: hidden;
  }

  &.sticky {
    &--up {
      .header, 
      .mdl-layout__drawer-button {
        transform: translateY(-250px);
      }
    }
    &--down {}

    &--dirty {
      .header {
        @extend .mdl-shadow--2dp;
      }
    }

    .main-container {
      padding-top: map-get($header-height, 'small-up');

      @media #{$medium-up} {
        padding-top: map-get($header-height, 'medium-up');
      }
    }

    .header, 
    .mdl-layout__drawer-button {
      will-change: transform, box-shadow;
      backface-visibility: hidden;
      top: 0;
      width: 100%;
      z-index: 100;
      position: fixed;
      transition: transform 450ms ease-in-out, box-shadow 350ms ease-in-out;
    }

    .header-nav {
      width: 100%;
    }
    
    .mdl-layout__drawer-button {
      z-index: 120;
      width: auto;
    }
  }
}

#header .header-content {
  @extend .clearfix;
  padding: 1.25rem 0 0.25rem;
  text-align: center;
  background: white;
  min-height: 4rem;

  .search-button {
    float: left;
    margin-left: 4rem;
    margin-top: -0.1rem;
    @media #{$small-only} {
        color: black;
    }
  }
  @media #{$medium-up}{
    padding: 0 1rem;
  }
  .logo {
    display: block;
    width: 60%;
    margin-left: 20%;
    text-align: center;
    @media #{$medium-up}{
      display: inline-block;
      float: left;
      width: 180px;
      margin-left: auto;
    }
    img {
      &.logo-large {
        display: none;
      }
      &.logo-small {
        width: 10rem;
        margin-top: -1rem;
      }
      @media #{$medium-up} {
        &.logo-small {
          display: none;
        }
        &.logo-large {
          display: block;
          max-width: 100%;
        }
      }
    }
  }
  .header-minicart {
    position: absolute;
    margin: 0.75rem 0;
    text-align: left;
    right: 0;
    top: 0;
    width: 48px;
    height: 48px;
    .cart-icon {
      position: relative;
      z-index: 2;
      display: inline-block;
      color: $cart-icon-color;
      width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
      margin-top: 1.1rem;

      .count {
        position: absolute;
        color: #FFF;
        background: $cart-count-color;
        padding: 0rem 0.3rem;
        line-height: 1rem;
        right: 0rem;
        top: -0.5rem;
        border-radius: 12%;
        font-size: 0.7rem;
        display: flex;
        span {
          margin: auto;
        }
      }
    }
    .cart-details {
      display: none;
      color: $cart-details-color;
      float: left;
      .cart {
        font-size: 1.2em;
      }
      .subtotal {
        font-size: 0.85em;
        .label {
          font-weight: 600;
        }
      }
    }
    @media #{$medium-up} {
      position: static;
      top: auto;
      right: auto;
      float: right;
      height: auto;
      width: auto;
      .cart-icon {
        float: left;
        margin-top: 1rem;
      }
      .cart-details {
        display: block;
      }
    }
    @media screen and (min-width: #{upper-bound($small-range)}) and (max-width: $content-width) {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
    @include clearfix();
  } // END header-minicart

  .header-search {
    @media #{$medium-up} {
       display: inline-block;
       width: 40%;
       vertical-align: middle;
    }
    #search_autocomplete {
      background: white;
      z-index: 20;
      border: 1px solid #F0F0F0;
      padding: 1rem 0;
      color: #333;
      text-align: left;
      li {
        padding-left: 1rem;
      }
      .selected {
        background: #F0F0F0;
      }
      .amount {
        display: none;
      }
    }
    .input-box {
      display: flex;
      white-space: nowrap;
      margin-left: -1rem;
      label {
        color: $mdl-grey-500;
        font-size: 14px;
      }
      input#search {
        width: 100%;
        font-size: 1rem;
        &:focus, &:focus + button.search-button  {
          // Focus input+button
        }
      }
      button.search-button {
        vertical-align: middle;
        height: 40px;
        margin-left: -0.25rem;
        font-size: 1.75rem;
        margin-top: 1rem;
        outline: none;
        background: transparent;
        border: 0;
        box-shadow: none;
        color: $color-polar;
        i {
          vertical-align: middle;
          font-size: inherit;
          margin-top: -0.5rem;
        }
        &:hover {
          color: darken($color-polar, 10%);
          cursor: pointer;
        }
      }
    }
  }

  @media #{$small-only} {
    .header-minicart {
      z-index: 2;
      margin-right: 0;
      margin-top: 0.2rem;
    }
    .header-search {
      position: absolute;
      padding: 1rem 2rem;
      display: none;
      width: 100%;
      background: #fafafa;
      z-index: 999;
      margin-top: 0.25rem;
      #search_mini_form {
        width: 100%;
        margin: auto;
        .search-wrapper {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
  .show-search {
    display: flex;
    animation: showsearch 0.4s ease;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    border-top: 2px solid $color-polar;
  }
}
