/* /ACCOUNT - _account.scss */

.customer-account-login {
  h1 {
    margin: 0;
  }
  .new-users,
  .registered-users {
    padding: 1rem 2rem;
    background: #fafafa;
    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
    a.mdl-button {
      color: #000000;
    }
  }
  ul.benefits {
    margin-bottom: 1em;
    padding-left: 2em;
    li {
      list-style: square;
    }
  }
  .forgot-password {
    margin-bottom: 1rem;
    margin-left: 0;
  }
  .registered-users {
    a.mdl-button {
      float: right;
      clear: left;
    }
  }
}

.customer-account {
  .ratings {
    i {
        color: $review-color;
    }
  }
  #my-orders-table {
    tr {
      @media #{$medium-up} {
        height: 4rem;
      }
    }
  }
  .data-table {
    border-spacing: 0;
    thead th {
      border-bottom: solid 2px $color-polar;
    }
    tr:nth-child(even) {
      background: rgba(0,0,0,0.03);
    }
  }
  #my-downloadable-products-table,
  .data-table {
    width: 100%;
  }
  #reorder-validate-detail {
    padding: 1rem 0;
    li {
      display: flex;
      input.checkbox {
        width: 10%;
      }
      .product-name {
        width: 90%;
      }
    }
  }
  h2 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }
  h3 {
    margin: 0;
    padding-top: 0.5rem;
    font-size: 1.3rem;
  }
  .col-left {
    @media #{$medium-up} {
      margin-top: 42px;
    }
  }
}

.remember-me-popup {
  display: none;
}

.customer-account {
    .main address {
        padding: 1rem 0;
        color: #777;
    }
    #my_recent_reviews {
        padding: 1rem;
        .product-name {
            font-size: 1rem;
        }
    }
    .product-review {
        display: flex;
        flex-direction: column;
        .rating {
            text-align: center;
        }
        @media #{$medium-up} {
            flex-direction: row;
            flex-wrap: wrap;
            .page-title {
                width: 100%;
            }
            .product-img-box {
                width: 25%;

            }
            .product-details {
                width: 75%;
            }
        }
        .label {
            display: none;
        }
        .amount {
            display: none;
        }
    }
    .pager .pages {
        & > strong {
            display: none;
        }
        ol {
            background: #fafafa;
            display: flex;
            margin: 1rem 0;
            li {
                padding: 0.5rem;
            }
        }
    }
    table.data-table {
        td,
        th {
            &.status {
                display: none;
            }
        }
    }
    @media #{$small-only} {
        table.data-table {
            thead {
                display: none;
            }
            tbody,
            td,
            th {
                border: 0 !important;
            }
            tr {
                display: flex;
                flex-direction: column;
                border: 1px solid #e0e0e0;
                height: auto;
                padding: 1rem;
                margin: 1rem 0;
            }
            td {
                padding: 1rem;
                .button {
                    width: 100%;
                    display: inline-block;
                    text-align: center;
                }
            }
        }
    }
    &-create {
        .account-create {
            margin: 6rem auto 10rem;
            li.control {
                margin: 1rem 0.5rem;
            }
            .back-link {
                display: none;
            }
        }
    }
    .page-title {
        h1 {
            text-transform: uppercase;
            font-weight: 300;
        }
    }
    .welcome-msg {
        background: #fafafa;
        padding: 1rem;
        margin: 1rem 0;
    }
    .col-left {
        margin-top: 32px;
    }
    .box-head {
        padding: 1rem;
        background: $account-bg;
        h2 {
            color: $account-title-color;
            font-weight: 300;
            line-height: 1.6rem;
            margin: 0;
            text-transform: uppercase;
            font-weight: 300;
        }
    }
    .box-info {
        background: rgba(0,0,0,0.05);
        margin: 1rem 0;
    }
    .data-table {
        .product-name {
            font-size: 1rem;
            margin: auto;
        }
    }
    .box-reviews {
        .number {
            display: none;
        }
        .ratings {
            & > strong {
                display: none;
            }
        }
        .item {
            border-bottom: 2px solid #f9f9f9;
        }
    }
    .block-account {
        background: rgba(0,0,0,0.05);
        .block-title {
            background: $account-bg;
            color: $account-title-color;
            padding: 1rem;
            text-transform: uppercase;
            margin-bottom: 1rem;
            @media #{$small-only} {
                line-height: 1.2;
            }
            strong span {
                font-weight: 300;
            }
        }
        .block-content {
            padding: 1rem;
            li {
                @media #{$small-only} {
                    border-bottom: #e0e0e0 1px solid;
                    padding: 1rem;
                    &:last-of-type {
                        border: 0;
                    }
                }
            }
        }
    }
    .col2-set {
        display: flex;
        padding: 1rem;
        @media #{$small-only} {
            flex-direction: column;
        }
        .col-1 {
            width: 100%;
            @media #{$medium-up} {
                width: 50%;
            }
        }
        .col-2 {
            width: 100%;
            @media #{$medium-up} {
                width: 50%;
            }
        }
    }
}

.customer-account-login {
    .page-title {
        padding: 1rem;
    }
    .new-users {
        .button-set {
            margin-top: 2rem;
            margin-bottom: 1rem;
            @media #{$medium-up} {
                margin-top: 4.5rem;
                margin-bottom: 0;
            }
        }
    }
    .new-users,
    .registered-users {
        border-radius: 3px;
        border: 1px #e0e0e0 solid;
        background: $account-panel-bg;
    }
}
