#polarcore {
  @media #{$small-only} {
    .mdl-layout__container {
      .mdl-layout__drawer__title {
        background-color: $color-01;
    }
    .nav-primary {
      li {
        a {
          color: black;
        }
      }
    }
    ul.top-links__mobile:first-of-type {
      border-top: 3px solid $color-01;
      a {
        color: white;
        }
      }
    }
  }
}
