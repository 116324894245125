#polarcore {
  .top-line {
    background-color: $body-bg-color; 
      border-bottom: 2px dashed $speider-dark;
    }
    #header {
      .header-content {
         background-color: $color-01; 
         padding: 0.5rem 1rem;
         .logo {
           float: none;
           width: 228px;
           max-height: 59px;
           margin-top: -0.3rem;
           @media #{$small-only} {
             margin-top: 0.9rem;  
          }
        }
      }
      .header-search {
        float: left;
        margin-top: 0.6rem;
        width: 300px;
        .search-wrapper  {
          border: none;
          margin-left: 0;
          background-color: $color-G7;
          .search-button {
            height: 0;
            margin-top: 0;
            i {
              color: $color-G4;
              margin-bottom: -10px;
            }
          }
          input#search{
            padding: 0.2rem 0.8rem;
            background-color: $color-G7;
            height: 40px;
            font-size: 1.1rem;
          }
        }
      }
      .header-minicart {
        > a {
          float: right;
        }
        width: 250px;
        .cart-icon {
          .material-icons {
            color: white;
          }
          .count {
            background-color: $color-G1;
            }
          }
          .cart-details {
            color: white;
      }
    }
  }
}
