$color-polar: #FF9E15;
$footer-container-bg-color: #3E3A2D;
$body-bg-color: #FFFEF4;
$product-box-color: #FBFAEE;
$grey-button-color: #F2F0E3;
$color-01: #FF9E15;
$color-02: #FFB65C;
$color-03: #FFCE92;
$color-04: #FFE6C7;
$color-G1: #263645;
$color-G2: #4D5A66;
$color-G3: #76808A;
$color-G4: #A2A8AF;
$color-G5: #D0D2D6;
$color-G6: #E7E8E9;
$color-G7: #F2F2F2;
$basic-text-link-color: $color-G1;
$text-link-color: $color-G1;
$basic-link-color: $color-G1;
$button-bg-color: $color-01;
$input-text-highlight-color: $color-01;
$input-text-label-color: $color-G4;
$speider-dark: $color-G1;

$loading-border-color: $color-G4;
