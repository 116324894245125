/* WIDGET - _wisget.scss */

.widget-new-products {
  .widget-title {
    //mixins.scss
    @include h2-with-line();
  }
  .widget-products {
    .products-grid {
      .add-to-links {
        display: flex;
        flex-direction: column;
        li {
          width: 100%;
          .inner {
            display: block;
          }
        }
      }
    }
  }
}

.widget-viewed {
  .widget-title {
    //mixins.scss
    @include h2-with-line();
  }
}


.title-with-line {
    //mixins.scss
    @include h2-with-line();
}

.banner-area {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  .banner-col-one {
    width: 100%;
    @media #{$large-up} {
      width: 65.5%;
    }
  }
  .banner-col-two {
    width: 100%;
    @media #{$medium-only} {
      display: flex;
      margin-top: 1rem;
    }
    @media #{$large-up} {
      width: 34.5%;
    }
    .banner-wrapper-two {
      margin-top: 2%;
      @media #{$medium-only} {
        margin-top: 0;
        margin-left: 0;
        padding-right: 1%;
        width: 50%;
      }
      @media #{$large-up} {
        margin-top: 0;
        margin-left: 2%;
      }
    }
    .banner-wrapper-three {
      @media #{$small-only} {
        margin-top: 2%;
      }
      @media #{$medium-only} {
        margin-top: 0;
        padding-left: 1%;
        margin-top: 0;
        max-width: 50%;
      }
      @media #{$large-up} {
        margin-top: 2%;
        margin-left: 2%;
      }
    }
  }
}

ul.category-button li {
  margin: 0.5rem;
  width: calc(50% - 1.1rem);
  border-radius: 4px;
  list-style: none;
}

ul.category-button {
    text-align: center;
    display: flex;
    flex-diretion: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    li {
      font-family: "Lato", sans-serif !important;
      font-weight: 200;
      height: auto;
      background: $category-button-bg;
      font-size: 1rem;
      color: $category-button-color;
      box-shadow: 0 2px 4px rgba(0,0,0,0.25);
      a {
        padding: 0.5rem;
        margin: 0;
        width: 100%;
        display: inline-block;
        font-size: 1rem;
        text-transform: uppercase;
        color: $category-button-color;
      }
   }
    .full-width {
        width: 100%;
    }
}

.nuva-dropdown-wrapper {
  position: relative;
  display: inline-block;
  user-select: none;
  ul, li {
    list-style: none;
    margin: 0;
    padding: 0
  }
  a {
    width: 100%;
    text-align: center;
    cursor: pointer;
    background: white;
    border: solid 1px #F0F0F0;
    border-radius: 2px;
    padding: 0.5em;
    &::after {
      display: none; // display: inline-block;
      content:"<>";
      transform: rotate(90deg);
    }
  }
  @media #{$small-only}{
    > a {
      position: absolute;
      top: 16px;
      right: 8px;
      width: 47.5%;
      float: right;
      padding: 0.75rem 1rem;
      margin: 0;
      text-transform: none;
      color: $color-polar;
      border-radius: 0px;
      border: 1px solid $color-polar;
      height: 3.25rem;
      line-height: 1.75rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:before {
        content: "\E164";
        @include material-icons();
        font-size: 1.25rem;
        margin-right: 0.5rem;
        display: inline-block;
        vertical-align: text-bottom;
      }
    }
  }
  .nuva-dropdown {
    position: absolute;
    top: -0.5em;
    left: 0.2em;
    background: #FFF;
    border: #f0f0f0 1px solid;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);;
    color: $mdl-grey-600;
    z-index: 30;
    transition: ease 0.2s;
    li {
      z-index: 50;
      position: relative;
      width: 100%;
      padding: 0.5em 1em;
    }
    li:hover {
      background: #F0F0F0;
      cursor: pointer;
    }
  }
}

.nuva-show {
  opacity: 1;
  visibility: visible;
}

.nuva-hide {
  visibility: hidden;
  opacity: 0;
}

#polarcore #bubble-layer-overlay {
    display: flex;
    background: rgba(255,255,255, 0.05);
    transition: ease-in-out 0.2s;
    .mdl-spinner {
        height: 64px;
        width: 64px;
    }
}

.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
} 

.embed-container iframe, .embed-container object, 
.embed-container embed { 
     position: absolute; 
     top: 0; 
     left: 0; 
     width: 100%; 
     height: 100%;
}
#polarcore .products-grid.slick-slider {
    .slick-dots {
      position: static;
      bottom: initial;
    }

    .slick-track:before,
    .slick-track:after {
        font-family: initial;
    }

    .slick-slide {
        outline: 0;
    }

    .slick-next {
        right: 0;
    }

    .slick-prev {
        left: 0;
    }

    .slick-next,
    .slick-prev {
        position: absolute;
        z-index: 80;
        width: 3rem;
        margin: auto;
        height: auto;
        background: rgba(255, 255, 255, 0.45) !important;
        display: flex !important;
        &:hover {
            background: rgba(0,0,0, 0.05) !important;
        }
    }

    .slick-next:before {
        transform: rotate(180deg);
    }

    .slick-next:before,
    .slick-prev:before {
        font-family: "Material Icons" !important;
        font-size: 3rem;
        content: '\E314';
        display: inline-block;
        height: 100%;
        margin: auto;
        font-family: initial;
        color: #222;
        background: none;
    }
}
