/* MODULES / AJAXCART - modules/ajaxcart/_ajaxcart.scss */
@if $import-ajaxcart == true {
  #polarcore {
    #ajaxcart, #ajaxcart-loading {
      top: 50%;
      left: 50%;
      margin-left: auto;
      transform: translate(-50%, -50%);
      font-smoothing: subpixel-antialiased;
      filter: blur(0);

      h2 {
        font-size: 1.2rem;
      
        @media #{$medium-up} {
          font-size: 1.575rem;
        }
      }

      .product {
        padding: 6px 0;
      }

      .actions {
        display: flex;
        flex-direction: column;

        .button, button {
          margin-right: 0;
          flex: 1;

          &:not(:last-child) {
            margin-bottom: 0.5rem;

            @media #{$medium-up} {
              margin-bottom: 0;
              margin-right: 0.5rem;
            }
          }
        }
        @media #{$medium-up} {
          flex-direction: row;
        }
      }
    }
  }

  #polarcore {
    #ajaxcart-loading .close, #ajaxcart .close, #ajaxcart-error .close {
      padding: 0;
      padding-left: 1px;
      padding-top: 6px;
      top: 0.5rem;
      right: 0.5rem;
      background: transparent;
      border-radius: 0;
      text-indent: -999px;
      &:before {
        @include material-icons();
        content: "\E5CD";
        position: absolute;
        top: 0;
        right: 0;
        color: $color-polar;
        font-size: 1.5rem; 
        text-indent: 0;
      }
    }
    #ajaxcart .actions button, #ajaxcart .actions .button {
      font-size: 1rem;
    }
  }
}
