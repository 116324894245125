/* MODULES / QUICKSHOP - modules/quickshop/_quickshop.scss */
@if $import-quickshop == true {
    #polarcore {
        #quickshop.quickshop-container {
            @media #{$small-only} {
                padding: 1rem;    
            }
        }
    }
}
