body {
    background-color: $color-G2;
    .main-container {
        background: white;
    }
    .button {
        border-radius: 3px;
    }
    .title-with-line h2 .center {
      color: $color-G1; 
    }
}
