/* GENERAL - _general.scss */

* {
  box-sizing: border-box;
}

body {
  background: $body-bg-color;
}

.main {
  padding: 0 0.5rem 0.5rem;
}

.page {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
}

.mdl-layout__content {
  z-index: initial;
}

.mdl-layout__obfuscator {
  z-index: 140;
}

.main-container {
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  background: $main-container-bg-color;
  @media #{$medium-up} {
    padding: 0 1rem;
  }
  @media #{$large-up} {
    padding: 0;
  }
}

.content-width {
  max-width: $content-width;
  margin: 0 auto;
}

ul, ol, li, dd, dt {
  list-style: none;
  margin: 0;
  padding: 0
}

main button, .button {
  background: $button-bg-color;
  color: $button-text-color;
  padding: 1em;
  border: none;
  outline: none;
  text-transform: uppercase;
  &:hover {
    transition: ease 0.2s background;
    cursor: pointer;
    background: darken($button-bg-color, 5%);
  }
}

img {
  max-width: 100%;
  height: auto;
}

.fullpage-image {
  // This ignores the padding and margin of mdl grid.
  // img {
  //   margin: -16px;
  //   width: calc(100% + 32px);
  //   max-width: none;
  //   margin-bottom: 1rem;
  // }
}

.col-wrapper {
  position: relative;
  clear: both;
}

a.button, a.link-wishlist, a.link-compare,
a.link-learn {
  text-decoration: none;
}

a {
  text-decoration: none;
  color: $basic-link-color;
  &:hover {
    color: darken($basic-link-color, $basic-link-hover-percentage);
  }
}

.polar-language-box {
  position: relative;
  z-index: 110;
  margin-top: 2px;
  .polar-language-button {
    font-size: 0.9rem;
    margin-top: 0.5em;
    color: $color-grey-dark;
    vertical-align: center;
  }
  .polar-language-button::after {
    content: ' \25BE ';
  }
  .polar-language-list {
    display: none;
    position: absolute;
    background: $polarcore-language-bg-color;
    border: 1px solid $color-grey-light;
    .polar-language-flex {
      display: flex;
      flex-direction: column;
      li:hover {
        background: darken($polarcore-language-bg-color, 5%);
      }
    }
  }
}

.messages {
  padding: 1em 0em;
  & > li {
    padding: 1em;
    background: $messages-bg-color;
    border: 1px solid $messages-border-color;
    color: $messages-text-color;
  }
  .success-msg {
    background: $success-msg-bg-color;
    color: $success-msg-text-color;
  }
  .error-msg {
    background: $error-msg-bg-color;
    color: $error-msg-text-color;
    display: flex;
    @media #{$large-up} {
      &::before {
        @include material-icons();
        content: '\E000';
        width: auto;
        font-size: 3em;
        margin: 0;
      }
    }
    & > ul {
      width: auto;
      margin-left: 1rem;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .note-msg {

  }
  .notice-msg {
    background: $notice-msg-bg-color;
    color: $notice-msg-text-color;
  }
}

.error {
  padding: 1em;
  background: $mdl-red-400;
  color: white;
  display: flex;
  text-align: left;
  span {
    margin: auto 1rem;
    text-align: left;
  }
  a {
    margin: 0 0.5rem;
    color: #EAEAEA;
    text-decoration: underline;
    &:hover {
        color: #f0f0f0;
    }
  }
  @media #{$large-up} {
    &::before {
      @include material-icons();
      content: '\E000';
      width: 5rem;
      font-size: 3em;
      margin: 0;
    }
  }
  & > ul {
    width: auto;
    margin:0;
  }
}

.mdl-textfield {
  width: 100%;
}

.mdl-checkbox__tick-outline {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  mask: url("../images/tick-mask.svg?embed");
  background: transparent;
  @include material-animation-default(0.28s);
  transition-property: background;
  .mdl-checkbox.is-checked & {
    background: $checkbox-color url("../images/tick.svg?embed");
  }
  .mdl-checkbox.is-checked.is-disabled & {
    background: $checkbox-disabled-color url("../images/tick.svg?embed");
  }
}

#map-popup {
  position: absolute;
  z-index: 200;
  width: 30%;
  background: white;
  border: 1px solid #e6e6e6;
  padding: 2rem;
  .btn-cart {
    @include btn-cart($add-to-cart-bg, $add-to-cart-text-color);
  }
}

/// Safari Fixes
input.mdl-textfield__input {
  appearance: none; //Removes outer border on inputs in safari
}

/// Search Index 
.catalogsearch-result-index {
  @media #{$small-only} {
    aside.col-left {
      min-height: 3rem
    }
    .page-title h1 {
      font-size: 1.4rem;
      text-align: center;
      margin-top: 2rem;
    }
    .toolbar .sorter .sort-by .mobile-sort {
      width: 100%;
    }
    .toolbar .sorter .sort-by .nuva-dropdown-wrapper .nuva-dropdown {
      width: 100%;
    }
  }
}

//Fix for rendering-issue with zoom-levels and mdl-checkboxes
.mdl-checkbox__box-outline {
  top: 15%;
  left: -1%;
}

.shipping-tracking-popup {
    padding: 3rem;
    .button {
        @include clearfix();
        margin: 1rem 0;
        float: right;
    }
    .buttons-set {
        @include clearfix();
    }
}
.tracking-popup-wrapper {
    border: 1px solid #f0f0f0;
    padding: 2rem;
    position: relative;
    .data-table tbody th {
        background: transparent;
    }
    .table-padding {
        padding: 1rem;
        background: #fafafa;
        border: 1px solid #eee;
        .odd {
            background: white;
        }
    }
    [id*=track-history] {
        width: 100%;
        thead tr {
            text-align: left;
        }
    }
    .page-title {
        @include clearfix();
        h1 {
            padding: 0;
            margin-bottom: 2rem;
        }
        .button {
            position: absolute;
            top: 1rem;
            right: 2rem;
        }
    }
}

.data-table {
    width: 100%;
    border-spacing: 0;
    margin: 1rem 0;
    @media #{$small-only} {
        padding: 1rem 0;
    }
    colgroup {
        display: none;
    }
    h3 {
        font-size: 1.25rem;
        margin: 0;
    }
    tfoot {
        border-top: 2px solid rgba(0,0,0,0.05);
        font-size: 1rem;
    }
    td:first-child {
        padding-left: 1rem;
    }
    td:last-child {
        padding-right: 1rem;
        text-align: right;
    }
    .label {
        font-weight: 400;
    }
    th {
        text-transform: uppercase;
        text-align: left;
    }
    tr {
        &:hover {
            td, th {
                border-bottom: 1px solid rgba($color-polar, 0.2);
                background: rgba(0,0,0,0.02);
            }
        }
    }
    td, th {
        padding-top:1rem;
        padding-bottom: 1rem;
        border-bottom: 1px $table-border solid;
        transition: border-color 0.1s ease-in-out;
        &:first-child {
            padding-left: 1rem;
            border-left: 1px solid $table-border;
        }
        &:last-child {
            padding-right: 1rem;
            text-align: right;
            border-right: 1px solid $table-border;
        }
    }
    tbody, tfoot, thead {
        tr:first-child {
            td,th {
                border-top: 1px solid $table-border;
            }
        }
    }
}
&.customer-account table.data-table {
   td h3 {
      font-size: 1rem;
   }
   tfoot {
      .grand_total td {
        border-bottom: 2px solid rgba(0,0,0,0.1);
      }
   }
}
#product-attribute-specs-table {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
