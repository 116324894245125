/* PRODUCT - _product.scss */

.product-view {
    #product-attribute-specs-table {
        .label {
            text-align: left;
        }
    }
    .select.input-box {
        position: relative;
        display: flex;
        border: 1px solid #E0E0E0;
        max-width: 100%;
        background: white;
        margin-bottom: 1rem;
        @media #{$medium-up} {
            max-width: 50%;
        }
        &:after {
            @include material-icons();
            position: absolute;
            user-select: none;
            pointer-events: none;
            right: 0rem;
            font-size: 1.2rem;
            content: '\E313';
            margin: auto;
            color: #444;
            height: 2.5rem;
            line-height: 2.5rem;
            vertical-align: middle;
            padding: 0rem 0.5rem;
            background: rgba(0, 0, 0, 0.05);
        }
        .validation-advice {
            right: 0;
            top: -1.5rem;
        }
        select {
            position: relative;
            appearance: none;
            border: 0;
            outline: none;
            background: transparent;
            width: 100%;
            height: 2.5rem;
            font-size: 0.9rem;
            padding-left: 0.75rem;

            @media #{$medium-up}{
              padding: 0.75rem;
            }
            &:hover {
                cursor: pointer;
                background: darken(#fff, 1%);
            }
            &:disabled {
                background: darken(#e0e0e0, 10%); 
                color: #444;
           }
           &::-ms-expand {
             display: none;
           }
       }
   }
   .add-to-cart-wrapper .swatch-attr .input-box {
       background: transparent;
       border: 0;
        &:after {
            content: '';
            display: none;
        }
        select {
            &:hover {
                cursor: pointer;
                background: transparent;
            }
            &:disabled {
                background: transparent; 
            }
        }
  }
  .product-shop .inner.grouped {
    background: transparent;
    padding: 0;
    .grouped-item {
      display: flex;
      position: relative;
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 1rem;
      div[id*="options_item"] {
        position: relative;
        .validation-advice {
          width: 50%;
          text-align: right;
        }
      }
      .product-image {
        width: 25%;
      }
      .product-info {
        width: 75%;
        padding: 0.5rem;
        .product-name {
          width: 75%;
          font-size: 1.2rem;
          margin: 0;
          line-height: 1.5rem;
          margin-bottom: 0.5rem;
          @media #{$medium-up}{
            line-height: 1.25rem;
            margin-right: 25%;
            font-weight: 700;
          }
          a {
            font-weight: inherit;
            color: inherit;
          }
        }
      } 
      .price-box {
        text-align: left;
        font-size: 1.2rem;
        @media #{$medium-up}{
          position: absolute;
          right: 0;
          top: 0.2rem;
          text-align: right;
        }
      }
    }
  }
  .product-shop .inner.bundle {
    .mdl-checkbox {
      height: auto;
    }
    .price-info {
      .price-box {
        padding: 0;
        .minimal-price,
        .price-from,
        .price-to {
          margin-bottom: 0;
        }
        .price-label {
          display: initial;
        }
      }
    }  
    .wrapper {
      .extra-info {
        @include clearfix();
        .availability {
          margin-bottom: 0;    
        }
      }
      .add-to-cart-wrapper {
        .price-box-bundle,
        .availability {
          display: none;
        }
        .product-options {
          .mdl-checkbox__label,
          .mdl-radio__label {
            font-size: 1rem;
          }
          dl {
            margin-top: 0;
            margin-bottom: 2rem;    
          }
          select {
            padding-right: calc(2.275rem + 0.75rem);    

            &[multiple="multiple"] {
                padding-right: 6px;
            }
          }
          .qty-holder {
            width: 100%;
            max-width: 75px;    

            .mdl-textfield__error {
              white-space: nowrap;    
            }
          }
          .input-box {
            margin-bottom: 0.5rem;    

            &--single {
                margin-bottom: 0.5rem;    
            }
          }
          .input-radio {
            margin-bottom: 0.5rem;    
          }
        }
      }
    }  
  }
  .mdl-grid {
    @media #{$small-only}{
      padding: 0;
    }
  }
  .mini-products-list {
    display: flex;
    .product {
      padding: 1rem;
    }
  }
  .product-name {
    font-weight: lighter;
    line-height: 1.5em;
  }
  .short-description {
    margin-bottom: 2em;
  }
  .product-image-gallery {
    .gallery-image {
      display: none;
    }
    .visible {
      display: block;
    }
  }
  .product-image-thumbs {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
  }
  .mini-description {
    position: relative;
    top: -1rem;
    min-height: 1rem;
    .sku-wrapper {
      margin-top: 0.4rem;
      float: right;
    }
    .sku { color: $article-sku-color; }
    .article-sku-label {
        padding-left: 1rem;
        padding-right: 0.25rem;
        color: $article-sku-color;
    }
    .ratings {
      font-size: 1rem;
      display: inline;
      .rating-box {
        display:inline-block;
        i {
          position: relative;
          top: 0.3em;
          color: $review-color;
          font-size: 1.5rem;
        }
      }
    }
  }
  .price-info {
    font-size: 1.6rem;
    text-align: right;
    float: left;
    width: 100%;
    @media #{$medium-up} {
        float: right;
        width: auto;
    }
    .price-label { display: none; }
    .price {
      font-weight: 200; // Or 800?
    }
    .price-box {
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem;
    }
    .old-price, .special-price, .regular-price {
      margin: 0;
      color: $price-color;
      padding: 0.2rem;
    }
    .regular-price {
      font-size: 1.6rem;
    }
    .old-price {
      text-decoration: line-through;
      color: $old-price-color;
      order: 3;
      .price {
        font-size: 1.3rem;
      }
    }
    .special-price {
      color: $special-price-color;
      font-size: 2rem;
      font-weight: 800;
      order: 1;
    }
    .tier-prices {
      padding-bottom: 1rem;
    }
    .map-info {
      a {
        font-size: 0.9rem;
      }
      .msrp-price-hide-message {
        font-size: 0.9rem;
        clear:both;
        float: right;
        @include clearfix();
      }
      .old-price {
        float: right;
        clear: both;
      }
    }
  }
  select {
    display: block;
    padding: 8px 6px;
    width: 100%;
    border: 1px solid $mdl-grey-300;
    border-radius: 2px;
    background-color: #fff;
    color: $mdl-grey-900;
    @media #{$small-only}{
      font-size: 16px;
    }
    @media #{$medium-up}{
      width: auto;
    }
  }
  img {
    transition: ease 0.2s;
  }
  #color_label, #size_label {
    font-weight: lighter;
  }
  .required {
    color: $mdl-grey-800;
    em {
      padding-right:0.25em;
      display: none;
    }
  }
  p.required {
    display: none;
  }

  dd.swatch-attr {
    padding: 0;
    margin: 0;
  }
  .configurable-swatch-list {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    li {
      width: 2em;
      height: 2em;
      padding: 0em;
      margin-right: 0.3em;
      margin-bottom: 1em;
      &.selected .swatch-label {
        img {
          border-radius: 50%;
        }
      }
    }
    .x {
      display: none;
    }
    .not-available {
      position: relative;
      &::after {
        color: $mdl-grey-600;
        top: -0.1em;
        left: 0.1em;
        font-weight: bold;
        font-size: 2em;
        position: absolute;
        content: '/';
        z-index: -3;
        padding: 0.1em;
      }
      img {
        opacity: 0.6;
      }
      a, a:hover {
        color: grey;
      }
    }
  }
  #configurable_swatch_size {
    .selected .swatch-label {
      border-bottom: 2px solid $select-hover-color;
    }
  }
  .availability .label {
    display: none;
  }
  .extra-info .in-stock .value {
    color: $mdl-green-500;
    font-size: 1.2em;
    &:before {
      content: '\2713';
      padding-right: 0.2em;
    }
  }
  .extra-info .out-of-stock .value {
    color: $mdl-red-500;
    font-weight: lighter;
    font-size: 1.2em;
    &:after {
      content: '\2717';
      padding-left: 0.2em;
    }
  }
  .extra-info .preorder .value {
    color: $mdl-orange-500;
    font-size: 1.2em;
    &:before {
      content: '\2713';
      padding-right: 0.2em;
    }
  }
  .product-name .h1 {
    font-size: 2.2em;
    margin: 0 0 0.25em 0;
    font-weight: 400;
  }
  .product-shop {
    & > .inner {
      background: #f1f1f1;
      padding: 1rem;
      position: relative;
      @media #{$medium-up}{
        padding: 2rem 3rem;
      }
    }
    .wrapper {
    }
  }
  .product-img-box {

  }
  .sharing-links {
    padding: 0;
    margin: 1em 0;
    list-style: none;
    display: flex;
    flex-direction: column;
  }
  .product-essential {
    width: 100%;
  }
  .product-collateral {
    width: 100%;
    clear: both;
    .mdl-tabs__panel ul, .mdl-tabs__panel ol{
      margin-left: 18px;
      li {
        list-style-type: disc;
        list-style-position: inside;
      }
      #customer-reviews {
        li {
          list-style: none;
        }
      }
    }
    h2 {
      display: none;
    }
    h3 {
      text-align: center;
    }
    h4 {
      margin-bottom: 6px;
    }
  }
  .link-compare .material-icons, .link-wishlist .material-icons {
    top: 0.25em;
    position: relative;
  }
  .add-to-links {
    display: none; /* Hidden by default */
    /* @include add-to-links(); */
  }
  .product-options {
    dd {
      margin-bottom: 1rem;
    }
  }
  .add-to-cart {
    button.btn-cart {
      @include btn-cart($add-to-cart-bg, $add-to-cart-text-color);
      max-width: 100%;
      display: block;
      clear: both;
      text-transform: uppercase;
      span.inner {
        position: relative;
        top: -0.2em;
      }
      &.out-of-stock {
        color: red;
        background: $mdl-grey-300;
      }
    }
    .qty,
    label[for=qty] {
      width: 2.8em;
      display: none;
    }
  }
}

.mdl-tabs__tab-bar {
    @media #{$small-only} {
        border: 0;
    }
}

.mdl-tabs {
  .tab-polarcore {
      @extend .mdl-tabs__tab;
  }
  @media #{$small-only} {
    display: flex;
    flex-direction: column;
    .mdl-tabs__tab {
        width: 100%;
        display: flex;
        border: 1px #f0f0f0 solid;
        border-bottom: 0;
        &:last-of-type {
            border: 1px #f0f0f0 solid;
        }
        span {
            display: inline-flex; 
            width: 100%;
        }
        span:after {
            @include material-icons();
            display: inline-block;
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
            content: "\E313";
            transform: rotate(-90deg);
            transition: transform 0.2s ease-in;
        }
        &.is-active {
            span:after {
                transform: rotate(0deg);
            }
        }
    }
    @keyframes tabsAnimation {
        from {
            opacity: 0;
            transform: translate(-50px);
            max-height: 0;
            overflow:hidden;
        }
        to {
            opacity: 1;
            transform: translate(0px);
            max-height: 5000px;
        }
    }
    .mdl-tabs__panel.is-active {
        animation: tabsAnimation 0.5s;
    }
  }
  .mdl-tabs__panel {
    margin-top: 1rem;
    @media #{$small-only} {
        padding: 1rem;
    }
  }
  .mdl-tabs__tabs:focus {
    outline: none;
  }
  .box-reviews {
    .review-list {
      order: 1;
      h4 {
        margin-bottom: 0.5rem;
      }
      .reviews {
        .review {
          span {
            display: inline-block;
            min-width: 4rem;
          }
          .rating-box {
            display: inline-block;
            position: relative;
            top: 0.5rem;
          }
        }
      }
    }
    .review-form {
      order: 5;
    }
  }
  .mdl-tabs__tab {
    padding: 0 1rem;
    @media #{$medium-up}{
      padding: 0 24px;
    }
  }
}

.box-up-sell {
  position: relative;
  padding-bottom: 2rem;
  &:before {
    content: "";
    position: absolute;
    border-bottom: $mdl-grey-300 1px solid;
    width: 100%;
    top: 1.7rem;
  }
  h2 {
    position: relative;
    text-align: center;
    font-size: 1.2rem;
    @media #{$small-only} {
      line-height: 2rem;
    }
    .center {
      background: white;
      padding: 0 2rem;
      @media #{$small-only} {
        padding: 0 2rem;
        display: inline-block;
        margin-top: 0.5rem;

      }
    }
  }
  ul {
    li.up-sell-item {
      padding: 1rem;
      .product-name {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        text-align: center;
      }
      @include grid-price-box();
    }
  }
}

.block-related {
  padding-bottom: 2rem;
  .block-title {
    // mixins.scss
    @include h2-with-line();
  }
  ol {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    li.item {
      padding: 1rem;
      text-align: left;
      position: relative;
      width: 100%;
      flex-basis: 100%;
      @media #{$medium-up} {
        width: 50%;
        flex-basis: 50%;
      }
      @media #{$large-up} {
        width: 33%;
        flex-basis: 33%;
      }
      &:hover {
          box-shadow: none;
      }
      .product-info .product-name {
        text-align: left;
      }
      .link-wishlist {
        display: none;
      }
      .product {
        display: flex;
        border: 2px solid #fafafa;
        @media #{$small-only} {
            .product-name {
                font-size: 1rem;
            }
        }
        .product-image {
            min-width: 128px; 
            img {
                    max-width: 128px;
                    max-height: 128px;
                }
        }
      }
     @include grid-price-box();
     .price-box {
        align-items: initial;
        justify-content: initial;    
     }

    }
    .related-checkbox, .mdl-checkbox.is-upgraded {
      position: absolute;
      top: 1.5rem;
      left: 2rem;
    }
  }
}
.products-grid .slick-track {
    .item:hover {
        box-shadow: none;
    }
    .up-sell-item.item {
        width: 200px;
    }
    .up-sell-item.item img {
        margin: 0 auto;
    }
}
