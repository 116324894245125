#polarcore {
  .account-create {
    #country {
      appearance: none;
      padding: 0.5rem;
      margin-top: 2rem;
      user-select: none;
    }
  }
}
