#polarcore {
  &.checkout-cart-index {
    main {
      .cart-total-price-wrapper {
        #show-discount-form {
          color: $color-G3;
        }
        .cart-totals {
          #shopping-cart-totals-table {
            color: $color-G1;
          }
        }
      }
      .cart {
        .btn-checkout {
          background-color: $color-01;
        }
      }
      #shopping-cart-table {
        li {
          .btn-remove {
            color: $color-G5;
          }
          .price-summary {
            .cart-price {
              color: $color-G1;
            }
          }
          .product-info {
            .short-description {
              color: $color-G3;
            }
            .product-name {
              a {
                color: $color-G1;
              }
            }
          }
        }
        .cart-footer {
          button.btn-update {
            background-color: $color-G6;
            color: $color-G1;
          }
        }
        #empty_cart_button {
          background-color: $color-G6;
          color: $color-G1;
        }
        button.btn-continue {
          background-color: $color-01;
        }
      }
        .cart-footer {
          .btn-update, #empty_cart_button {
            background: $grey-button-color;
      }
    }
    li {
      background: $product-box-color;
      .btn-remove {
        background: $product-box-color;
        }
      } 
    } 
  }
}
