#polarcore {
  .category-title {
    h1 {
      color: $color-G1;
    }
  }
  .category-products {
    .toolbar {
      .sorter {
        .amount { 
          color: $color-G1;
        }
        .sort-by {
          color: $color-G1;
          .nuva-dropdown-wrapper {
            a {
              color: $color-G3;
            }
          }
        }
      }
    }
  }
  .products-grid {
    .item {
      position: relative;
      .product-info {
        .price-box {
          .regular-price {
            color: $color-G3;
          }
        }
        .product-name {
          a {
            color: $color-G1;
          }
        }
      }
    }
    .actions {
      min-height: 4.5rem;
      display: initial;

      .view-details {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
        padding: 0.8rem;
      }
     
      .button {
        display: initial;
        background-color: $color-01;

     }
      .add-to-cart-wrapper {
        display: flex;
        padding-top: 2rem;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;

        .qty {
          color: #757575;
          text-align: center;
          width: 3.2rem;
          font-size: 1rem;
          border-right: 0;
          background-color: #fff;
          border-radius: 3px 0 0 3px;
          border-color: $color-polar;
        }

        .button {
          flex: 1;
          border-radius: 0 3px 3px 0;
        
          .inner {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  .block-layered-nav {
    .block-content {
      dd {
        ol {
          li {
            a {
              color: black;
            }
          }
        }
      }
    }
  }
}
