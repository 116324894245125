#polarcore {
  .product-view {
    .product-shop {
      & > .inner {
        background: $product-box-color;
        border-radius: 3px;
        border: 1px solid #E2E0BB;
        .add-to-cart {
          display: flex;
          width: 100%;
          align-items: center;
          }
          .qty {
            width: 3.5rem;
            border-radius: 3px;
            text-align: center;
            font-size: 1rem;
            color: #757580;
            padding: 1rem 0.75rem;
            border-color: #E2E0BB;
            margin-right: 0.75rem;
            display: initial;
            }
          }
        }
      }
      .btn-cart {
        flex: 1;
      }
      .mini-description {
        .brand-wrapper {
          color: #BBB;
          float: right;
      }
      .sku-wrapper {
        clear: both;
        margin-top: 0rem;
    }
  }
}
