/* Custom TCO - custom/after/_tco.scss */
body.tco.tco-onestep {
  h2 {
    font-size: 16px;
    margin-top: 1rem;
    line-height: 1.5rem;
  }
  .login-text {
    margin-top: 5rem;
    margin-left: 0.3rem;
  }
  @media #{$small-only} {
    h2 {
      margin-top: 1rem;
      line-height: 2rem;
    }
  }
  .checkout {
    .back {
      margin-top: 3rem;
      color: $color-G3;
      @media #{$medium-up} {
        margin-top: 1rem;
      }
    }
  }
  .tco-wrapper {
    input.input-text {
      border: 1px solid $color-G6;
    }
    p.login-link {
      //margin-top: 100px;
      @media #{$medium-up} {
        margin-top: 16px;
        display: flex;
      }
      a {
        @extend .button;
        display: block;
        text-align: center;
        max-width: 260px;
        padding: 1rem;
//        color: $color-G3;
        color: white;
        background: $color-01;
        margin: 2rem 0;
        margin-bottom: 0;
        @media #{$medium-up} {
//          margin-top: 5rem;
          margin-right: 1rem;
        }
        &:hover {
          background: $color-01;
        }
      }
    }
    #checkout-review-submit {
      .discount {
        width: 75%;
        margin-bottom: 1rem;
        label {
          float: left;
          width: 100%;
        }
        .input-box {
          float: left;
          width: 48%;
          #coupon_code {
            height: 32px;
            margin: 0;
          }
        }
        .buttons-set {
          float: left;
          width: 48%;
          margin-top: 0;
          margin-left: 2%;
          .button {
            background-image: none;
            background-color: $color-G3;
          }
        }
      }
    }
    .step-title {
      border-bottom: 2px solid $color-01;
      span {
        color: $color-G1;
      }
      h2 {
        color: $color-G3;
      }
    }
    label {
      color: $color-G1;
    }
    input:checked + label {
      color: $color-G1;  
    }
    input + label {
      color: $color-G1;
    }
    .sp-methods {
      dt {
        font-weight: bold;
      }
      .carrier-method-desc {
        color: $color-G3;
      }
    }
    #review-buttons-container {
      .btn-checkout {
        background-image: none;
        background-color: $color-01;
      }
    }
  }
}
