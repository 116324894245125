/* MODULES / CLICK AND COLLECT - modules/clickandcollect/_cac.scss */

@if $import-cac == true {
  #polarcore {
    //Header Link
    #cac-cart-link {
      @media #{$small-only}{
        position: absolute;
        top: 0.25rem;
        right: 3rem;
        margin-top: 0rem;
        padding-right: 0.5rem;
        padding-bottom: 0.25rem;
        text-indent: -999px;
        overflow: hidden; 
        border-color: rgba(255,255,255,0.5);
      }
      position: relative;
      float: right;
      margin: 1rem 1.5rem 1rem 0;
      padding-right: 1.5rem;
      text-align: left;
      font-size: 1.2em;
      color: inherit;
      border-right: 1px solid #e0e0e0;
      #cac-cartlink-qty {
        position: absolute;
        color: #FFF;
        background: #00C853;
        padding: 0rem 0.3rem;
        line-height: 1rem;
        left: 0.75rem;
        top: 0.5rem;
        border-radius: 12%;
        font-size: 0.7rem;
        text-indent: 0;
      }
      &:before {
        @include material-icons();
        content: "\E88A";
        float: left;
        font-size: 24px;
        margin-top: 1rem;
        margin-right: 0.5rem;
        text-indent: 0;
        @media #{$small-only}{
          color: white;
        }
      }
    }
    //Product List
    .products-grid {
      .stock-status {
        @media #{$medium-up}{
          display: flex;
          justify-content: center;
        }
        line-height: 1.25rem;
        margin-bottom: 0.5rem;
        .cac-status, .webshop {
          white-space: nowrap;
          padding-right: 0.75rem;
          @media #{$small-only}{
            padding: 0;
          }
          .status {
            &:after {
              position: static;
              content: "\2713";
              height: auto;
              width: auto;
              font-size: 1.2rem;
              background: none;
              color: #4CAF50;
            }
            &.out-of-stock {
              color: #F44336;
              &:after {
                display: inline-block !important;
                content: "\2717";
                color: #F44336;
              }
            }
          }
        }
      }
    }
    //Product Page functionality
    .product-view .add-to-cart-wrapper {
      #cac_store_chosen {
        float: left;
        max-width: 65%;
        margin-right: 2%;
        .chosen-single {
          height: 42px;
          padding: 0.5rem 0.75rem;
          background: white;
          border-radius: 0px;
          border: 1px solid #e0e0e0;
          font-size: 0.95rem;
          text-transform: uppercase;
          box-shadow: none;
          b {
            background-position: 2px 10px;
          }
        }
        .chosen-drop {
          box-shadow: none;
          border-radius: 0px;
          border: 1px solid #e0e0e0;
          .chosen-results li.highlighted {
            background: #2196F3;
          }
        }
      }
      #clickandcollect-addtocart {
        float: none;
        max-width: 33%;
        height: 42px;
        margin: 0;
        padding: 0 0.5rem;
        font-weight: normal;
        background-color: #2196F3;
      }
    }
    //Popup
    #cac-cart-wrapper {
      h1 {
        margin-bottom: 2rem;
      }
      border: 1px solid #f0f0f0;
      box-shadow: 0px 0px 100px -10px rgba(0,0,0,0.2);
      .close {
        button {
          padding: 0.4rem 0.8rem;
          font-size: 0.95rem;
          color: black;
          background-color: white;
          border: 1px solid black;
          letter-spacing: 0.5px;
          &:hover {
            color: #888;
            border-color: #888;
          }
        }
      }
      #cac-cart-items {
        .table-wrapper {
          .item-header {
            background-color: #fafafa;
          }
          .items-wrapper {
            .item-line {
              background-color: transparent;
              border: none;
              border-bottom: 1px solid #e0e0e0;
              .name {
                line-height: 1.5rem;
              }
              .qty {
                width: 15%;
                .qty-add, .qty-sub {
                  display: inline-block;
                  width: 2rem;
                  height: 2rem;
                  color: white;
                  line-height: 1.25rem;
                  font-weight: normal;
                  background-color: #2196F3;
                }
                span {
                  display: inline-block;
                  vertical-align: middle;
                  min-width: 2rem;
                }
              }
              .remove {
                width: 3%;
                text-indent: -999px;
                margin-right: 0.25rem;
                &:before {
                  display: none;
                }
                &:after {
                  @include material-icons();
                  position: absolute;
                  top: 0.4rem;
                  right: 0.25rem;
                  content: "\E5CD";
                  text-indent: 0px;
                  font-size: 1.25rem;
                  cursor: pointer;
                }
                &:hover:after {
                  color: #888;
                }
              }
            }
          }
        }
      }
      #cac-checkout-link {
        button {
          padding: 1rem;
          color: white;
          border: none;
          font-size: 0.95rem;
          font-weight: normal;
          background-color: #2196F3;
          cursor: pointer;
        }
      }
      #cac-checkout-form {
        label {
          margin-right: 0.25rem;
          font-size: 1rem; 
        }
        input {
          margin-right: 1.5rem;
        }
        .buttons {
          button {
            float: right;
            padding: 1rem;
            color: white;
            border: none;
            font-size: 0.95rem;
            font-weight: normal;
            background-color: #2196F3;
            cursor: pointer;
          }
          .back button {
            padding: 0.4rem 0.8rem;
            margin-top: 2.2rem;
            color: black;
            border: 1px solid black;
            background-color: transparent;
            &:hover {
              color: #888;
              border-color: #888;
            }
          }
        }
      }
      .pickup-notification {
        .messages {
          padding: 0;
          text-align: left;
          .notice-text {
            font-weight: normal;
          }
          .notice-msg {
            background-color: #f05c5c;
          }
        }
      }
    }
  }
}
