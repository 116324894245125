#polarcore {
  .footer-container {
    h2 {
      color: $color-G5;
    }
    li, p,  a {
      color: $color-G7;
    }
    background-color: $color-G1;
    #newsletter-validate-detail {
      .button {
        background-color: $color-G5;
      }
    }
  }
  .footer-container .back-to-top__icon {
      background-color: $color-03;
  }
}
