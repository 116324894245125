/* MODULES / TCO - modules/tco/_tco.scss */
@if $import-tco == true {
    body.tco.tco-onestep {
       #login-step {
            a.btn-close {
                background: none;
                border: 0;
                color: white;
                &:hover {
                    color: #afafaf;
                }
            }
       }
       #login-step-login {
            input {
                min-width: 300px;
            }
       }
       p.login-link {
            margin-bottom: 3rem;
            padding-left: 0.5rem;
       }
       .please-wait {
            display: flex;
            img {
                margin: auto;
                padding: 2rem;    
                opacity: 0.2;
            }
        }
        .main-container.col1-layout.checkout {
            margin-top: 0;
            margin-bottom: 3rem;
            //border-bottom: 0.5rem solid #2c313e;
        }
        .checkout .logo {
            max-width: 180px;
        }
        .checkout .back {
            margin-top: 1rem;
        }
        .tco-wrapper .section .step-title, .tco-wrapper .step-title {
            //background: #2c313e; 
            background: none; 
            border: 0;
            border-bottom: 2px solid $color-polar;
            border-radius: 0;
            box-shadow: none;
            h2 {
                color: $color-polar;
                text-transform: uppercase;
                font-weight: 300;
            }
        }
        .tco-wrapper {
            #login-step-login {
                & > p {
                    margin-top: 2rem;
                }
            }
            button.btn-checkout {
                background: $add-to-cart-bg;
                font-size: 1.2rem;
                float: right;
                transistion: background ease-in-out 0.2s;
                border-radius: 0; 
                @media #{$small-only} {
                    width: 100%;
                }
                &:hover {
                    background: darken($add-to-cart-bg, 5%);
                }
                &:after {
                    @include material-icons();
                    content: '\E315';
                    display: inline;
                    line-height: 1rem;
                    font-size: 2rem;
                    vertical-align: middle;
                    position: relative;
                    top: -2px;
                }
            }
            .login .form-list button.button {
                background: $color-polar;
                transistion: background ease-in-out 0.2s;
                &:hover {
                    background: darken($color-polar, 5%);
                }
            }
            #checkout-shipping-method-load {
              .radio {
                margin-top: 0px;
                margin-right: 3px;
              }
            }
            #checkout-review-table tbody td:first-child {
                width: 64px;
            }
            .item-options {
                text-align: left;
            }
            .data-table .product-name {
                line-height: 1.4em;
                text-align: left;
            }
            label {
                font-weight: 400;
                color: #666;
            }
            dd {
                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            dt {
                color: #666;
                font-weight: 400;
            }
            input + label {
                padding: 0;
            }
            input.input-text {
                border: 0;
                border-bottom: 2px solid #f0f0f0;
                outline: none;
                box-shadow: none;
                &:focus {
                    border: 0;
                    border-bottom: solid $color-polar 2px;
                }
            }
            input[type="checkbox"] {
                &:focus + label {
                    color: darken($color-polar, 10%); 
                }
            }
            input:checked + label {
                color: lighten($color-polar, 10%);
            }
            .form-list .field {
                width: 100%;
                padding: 0;
            }
            .step {
                border: 1px solid #f0f0f0;
                .number {
                    border: 0;
                    border-radius: 2px;
                    font-style: Verdana;
                    line-height: 1.5rem;
                    text-align: center;
                    box-shadow: none;
                }
            }
            .wide {
                margin-bottom: 1.5rem;
            }
        }
    }

    body.tco .tco-wrapper label[for="shipping:country_id"] + .input-box,
    body.tco .tco-wrapper label[for="billing:country_id"] + .input-box, 
    body.tco .tco-wrapper label[for='billing-address-select'] + .input-box {
            position: relative;
            display: flex;
            border: 1px solid #E0E0E0;
            max-width: 100%;
            background: white;
            margin-bottom: 1rem;
            padding-right: 2rem;
            text-overflow: ellipsis;
            &:after {
                @include material-icons();
                position: absolute;
                user-select: none;
                pointer-events: none;
                right: 0rem;
                font-size: 1.2rem;
                content: '\E313';
                margin: auto;
                color: #444;
                height: 2.5rem;
                line-height: 2.5rem;
                vertical-align: middle;
                padding: 0rem 0.5rem;
                background: rgba(0, 0, 0, 0.05);
            }
            .validation-advice {
                right: 0;
                top: -1.5rem;
            }
            select {
                position: relative;
                appearance: none;
                border: 0;
                outline: none;
                padding-right: 2rem;
                background: transparent;
                width: 100%;
                height: 2.5rem;
                @media #{$medium-up}{
                  padding: 0.75rem;
                }
                &:hover {
                    cursor: pointer;
                    background: darken(#fff, 1%);
                }
                &:disabled {
                    background: darken(#e0e0e0, 10%); 
                    color: #444;

               }
        }
    }


    body.tco.tco-onestep .tco-wrapper table.data-table td.a-right.last {text-align: right;}

    body.tco.tco-onestep .data-table {
        td:first-child {
            padding-left: 1rem;
        }
        td:last-child {
            padding-right: 1rem;
            text-align: right;
        }
        th {
            text-transform: uppercase;
            text-align: left;
        }
        td, th {
            padding-top:1rem;
            padding-bottom: 1rem;
            border-bottom: 1px $table-border solid;
            transition: border-color 0.1s ease-in-out;
            &:first-child {
                padding-left: 1rem;
                border-left: 1px solid $table-border;
            }
            &:last-child {
                padding-right: 1rem;
                text-align: right;
                border-right: 1px solid $table-border;
            }
        }
        tbody, tfoot, thead {
            tr:first-child {
                td,th {
                    border-top: 1px solid $table-border;
                }
            }
        }
    }
}
