/* MODULES / KCO - modules/kco/_kco.scss */

@if $import-kco == true {
  $kco-button-color: #0072cc !default;
  $kco-button-hover-color: #006cc2 !default;
  $kco-header-color: black !default;
  $kco-header-background-color: black !default;

  #polarcore {

    //Button Colors
    #kco_login .buttons-set button,
    #coupon_code_section #coupon-code-form button,
    #kco_review .cart #shopping-cart-table tr .input-wrapper .qty-change,
    #kco_postal .postal-form button,
    input#kco_newsletter_checkbox:checked + label:before {
      background-color: $kco-button-color;
      &:hover {
        background-color: $kco-button-hover-color;
      }
    }

    /* Header colors */
    &.klarna-checkout .col-main .page-title h2 {
        color: $kco-header-color;
    }
    &.klarna-checkout .col-main .page-title h2:before {
        background-color: $kco-header-background-color;
    }

    #kco-back-to-store {
      line-height: 45px;
    }

    #kco-logo {
      padding: 0.75rem 0;
    }

    .page-title {
      margin-bottom: 1rem;
      h2 {
        margin: 0;
      }
    }
    #login-popup { 
      margin: 1rem 0;
    }
    #kco_login {
      h2 {
        margin: 0;
      }
      #login-form {
        label {
          font-size: 1.1rem;
          margin-bottom: 0.25rem;
        }
        input {
          border: 1px solid #e0e0e0;
        }
      }
      .buttons-set {
        text-align: right;
        a {
          margin-right: 0.5rem;
        }
      }
    }

    #kco_review {
      overflow-x: auto;
      @media #{$medium-up}{
        overflow-x: visible;
      }
      #shopping-cart-table {
        border-spacing: 0px;
        tr {
          margin-bottom: 0;
          @media #{$medium-up}{
            td {
              border-bottom: 1px solid #e0e0e0;
              &:nth-of-type(3) a { 
                display: none;
              }
              &:first-of-type {
                border-left: 1px solid #e0e0e0;
              }
              &:last-of-type {
                border-right: 1px solid #e0e0e0;
              }
            }
            &:last-of-type td {
              border-bottom: none;
            }
          }
        }
        .product-image {
          img {
            max-width: none;
            max-height: 50px;
            width: auto;
          }
        }
        .product-name {
          line-height: 1.5rem;
          font-size: 1rem;
          margin-left: 0.5rem;
        }
        .item-options {
          margin-left: 0.5rem;
        }
        input.qty {
          max-width: 2.5rem;
        }
        a.btn-remove {
          display: inline-block;
          text-indent: -999px;
          text-align: left;
          overflow: hidden;
          @media #{$medium-up}{
            position: relative;
          }
          &:after {
            @include material-icons();
            position: absolute;
            top: 0.25rem;
            right: 0.25rem;
            content: "\E5CD";
            text-indent: 0;
            font-size: 1.25rem;
          }
        }
        @media #{$small-only}{
          tr {
            display: block;
            position: relative;
            border: 1px solid #e0e0e0;
            border-bottom: none;
            .product-image {
              margin-right: 0.75rem;
              img {
                max-height: 75px;
                margin-top: 0.25rem;
              }
            }
            .product-name {
              font-size: 1.25rem;
            }
            .input-wrapper {
              text-align: center;
              .qty-change {
                font-weight: normal;
                font-size: 1.25rem;
                width: 28px;
                height: 28px;
                line-height: 28px;
              }
            }
            .cart-price {
              padding: 1rem 0;
              font-size: 1.25rem;
            }
            .btn-remove {
              right: 0;
            }
          }
        }
      }
    }
    #kco_newsletter {
      label {
        input {
          margin-bottom: 3px;
        }
      }
    }
    #coupon_code_section {
      #coupon-code-form {
        margin-top: 0.5rem;
      }
    }
    #payment_kco {
      #payment_select {
        @media #{$small-only}{
          li {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}
