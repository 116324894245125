#polarcore {
  #ajaxcart-loading, #ajaxcart, #ajaxcart-error {
    box-shadow: 0 20px 170px rgba(0,0,0,0.1);
  }
  #ajaxcart {
    img.success {
      width: 64px;
    }
    .actions {
      .button {
        background-color: $color-02;
      }
      .button.cart {
        background-color: $color-01;
        background-image: none;
      }
    }
    h2 {
      color: $color-G1;
    }
  }
  .product {
    .product-name {
      color: $color-G1;
    }
    .price-box {
      color: $color-G1;
    }
  }
}
