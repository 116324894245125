#polarcore {
  .top-line {
    background-color: $color-01;
    border-bottom: 2px solid $color-02;
    .content-width {
      ul.top-links-right, ul.top-links-left {
        li a {
          color: $color-G1;
          font-weight: bold;
        }
      }
    }
  }
}
