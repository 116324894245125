#polarcore {

  $meganav-background-color: white;
  $meganav-link-bg-color: $color-G1;
   
  $meganav-columns: 5;
  $meganav-font-size: 0.9rem;
   
  $meganav-header-color: darken($nav-background-link-color, 15%);
  $meganav-header-hover-color: white;
   
  $meganav-link-color: black;
  $meganav-link-hover-color: white;
   
  $meganav-border-color: $color-G5;
   
  .header-nav {
    background-color: $color-G1;
    #nav {
      .nav-primary {
        > li {
          position: static;
          &.active {
            background-color: $color-G2;
          }
          &:hover {
            background-color: $color-G3;
          }
          > ul {
            display: none;
            left: 0;
            width: 100%;
            border: none;
            background-color: $meganav-background-color;
            @extend .mdl-shadow--4dp;
            li {
              a:hover {
               // background-color: $color-G1 !important;
              }
              &.view-all {
                display: none;
              }
            }
            > li {
              position: relative;
              width: 100%/$meganav-columns;
              padding: 1.25rem;
              background-color: transparent;
              &:after {
                content: "";
                position: absolute;
                right: 0;
                top: 10%;
                bottom: 10%;
                width: 1px;
                background-color: transparent;
                border-right: 1px dashed $meganav-border-color;
              }
              &:last-of-type:after {
                display: none;
              }
              & > a:hover {
                background-color: $meganav-link-bg-color;
                color: $meganav-header-hover-color;
              }
              a {
                height: auto;
                display: block;
                color: $meganav-header-color;
                font-size: $meganav-font-size;
                font-weight: 500;
                padding: 0.25rem 0.5rem;
                color: $color-G1;
                &:before {
                  display: none;
                }
              }
              > ul {
                position: static;
                display: block;
                border: none;
                background-color: transparent;
                margin-top: 0.75rem;
                li {
                  &.category-node-44 {
                    a {
                      color: $color-G2; 
                    }
                  }
                  background-color: transparent;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  a {
                    color: $color-G3;
                  }
                  &:hover, &.menu-active {
                    > a {
                      background-color: $color-01;
                      color: white;
                    }
                  }
                  a {
                    padding: 0rem 0.5rem;
                    margin-bottom: 0;
                  }
                }
                ul {
                  display: none;
                }
              }
            }
          }
          &.menu-active, &:hover {
            ul {
              display: flex;
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
}
